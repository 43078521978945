import React from 'react';
import serviceSixteen from '../../../images/svg/tax.svg';
import serviceSeventeen from '../../../images/svg/Air BNB Tax Returns.svg';
import serviceEighteen from '../../../images/svg/UBER and Taxi Operators.svg';
import serviceNineteen from '../../../images/svg/Family and Day Care Center.svg';

const TaxCard = () => {
    return (
        <>
            <div className="mb-3 mt-5">
                <h5 className="text-uppercase brand-color text-center">individual tax returns</h5>
                <div className="row mt-4">
                    <div className="col-lg-12 mb-1">
                        <div className="single-service-card d-flex p-4 border rounded ">
                            <div>
                                <img src={serviceSixteen} alt="services" className="service-card-img" />
                            </div>
                            <div className="ml-4">
                                <h5 className="text-uppercase">Individual and Sole Traders Tax Returns </h5>
                                <p className="service-sub-title">We ensure maximum Tax Refund for Individuals within ATO’s Guide Lines.</p>
                                <p className="service-sub-title">We provide a Guide to ultimate Tax Deduction</p>
                                <p className="service-sub-title">Tax Tips for future tax returns </p>
                                <p className="service-sub-title">Preparation and Lodgments of Overdue and Late Tax returns</p>
                                <p className="service-sub-title">Special Offer for under 21’s and Apprentices </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="single-service-card d-flex p-4 border rounded">
                            <div>
                                <img src={serviceSeventeen} alt="services" className="img-fluid service-card-img" />
                            </div>
                            <div className="ml-4">
                                <h6 className="text-uppercase">Air BNB Tax Returns</h6>
                                <p className="service-sub-title">Preparation of tax returns</p>
                                <p className="service-sub-title">And BAS for home based businesses</p>
                                <p className="service-sub-title">Air BNB stays</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="single-service-card d-flex p-4 border rounded">
                            <div>
                                <img src={serviceEighteen} alt="services" className="img-fluid service-card-img" />
                            </div>
                            <div className="ml-4">
                                <h6 className="text-uppercase">UBER and Taxi Operators</h6>
                                <p className="service-sub-title">BAS preparation</p>
                                <p className="service-sub-title">Tax return lodgement</p>
                                <p className="service-sub-title">Equipment deduction</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="single-service-card d-flex p-4 border rounded">
                            <div>
                                <img src={serviceNineteen} alt="services" className="img-fluid service-card-img" />
                            </div>
                            <div className="ml-4">
                                <h6 className="text-uppercase">Family and Day Care Center</h6>
                                <p className="service-sub-title">GST collection through BAS</p>
                                <p className="service-sub-title">Tax returns with maximum deductions</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TaxCard;