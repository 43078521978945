import React from 'react';
import serviceSeven from '../../../images/svg/accounting.svg';
import serviceEight from '../../../images/svg/Wage Subsidy Program.svg';
import serviceNine from '../../../images/svg/Worker’s Compensation.svg';
import serviceTen from '../../../images/svg/inventory.svg';
import serviceEleven from '../../../images/svg/IAS.svg';
import serviceTwelve from '../../../images/svg/ATO.svg';
import serviceThirteen from '../../../images/svg/FBT.svg';
import serviceFourteen from '../../../images/svg/SUPERANNUATION.svg';
import serviceFifteen from '../../../images/svg/Equipment Finance.svg';

const AccountServicesCard = () => {
    return (
        <div>
            <h5 className="text-uppercase brand-color text-center">Accounting Services</h5>
            <p className="gray-text text-center px-lg-5 px-md-5 px-2 pb-2">Our accounting team has years of expertise to help you navigate complex business regulations. We help create business-savvy entrepreneurs who can execute their business plans confidently.</p>
            <div class="card-columns">
                <div class="card">
                    <div className="single-service-card d-flex p-4">
                        <div>
                            <img src={serviceSeven} alt="services" className="service-card-img" />
                        </div>
                        <div className="ml-4">
                            <h5 className="text-uppercase">accounting services</h5>
                            <p className="service-sub-title">Budget & cash flow management</p>
                            <p className="service-sub-title">Reporting and Preparation of ASIC compliance documents for Non Profit Organizations</p>
                            <p className="service-sub-title">Accounts payable & receivable</p>
                            <p className="service-sub-title">Month, quarter & year-end review</p>
                            <p className="service-sub-title">Financial control review</p>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div className="single-service-card d-flex p-4">
                        <div>
                            <img src={serviceNine} alt="services" className="service-card-img" />
                        </div>
                        <div className="ml-4">
                            <h5 className="text-uppercase">Worker’s Compensation </h5>
                            <p className="service-sub-title">We prepare claims for Workers Compensation documents for your Employees </p>
                        </div>
                    </div>
                </div>

                

                <div class="card">
                    <div className="single-service-card d-flex p-4">
                        <div>
                            <img src={serviceTen} alt="services" className="service-card-img" />
                        </div>
                        <div className="ml-4">
                            <h5 className="text-uppercase">INVENTORY</h5>
                            <p className="service-sub-title">Management & reconciliation</p>
                            <p className="service-sub-title">Inventory reductions</p>
                            <p className="service-sub-title">Stocktaking</p>
                            <p className="service-sub-title">Capital expenditure concessions</p>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div className="single-service-card d-flex p-4">
                        <div>
                            <img src={serviceTwelve} alt="services" className="service-card-img" />
                        </div>
                        <div className="ml-4">
                            <h5 className="text-uppercase">ATO COMPLIANCE</h5>
                            <p className="service-sub-title">ATO regulation requirements</p>
                            <p className="service-sub-title">All ATO lodgements</p>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div className="single-service-card d-flex p-4">
                        <div>
                            <img src={serviceEleven} alt="services" className="service-card-img" />
                        </div>
                        <div className="ml-4">
                            <h5 className="text-uppercase">ias/bas</h5>
                            <p className="service-sub-title">GST requirements</p>
                            <p className="service-sub-title">Installment Activity Statements</p>
                            <p className="service-sub-title">Business Activity Statements</p>
                            <p className="service-sub-title">Monthly & Quarterly reporting</p>
                        </div>
                    </div>
                </div>
          

                <div class="card">
                    <div className="single-service-card d-flex p-4">
                        <div>
                            <img src={serviceThirteen} alt="services" className="service-card-img" />
                        </div>
                        <div className="ml-4">
                            <h5 className="text-uppercase">fbt</h5>
                            <p className="service-sub-title">Vehicles</p>
                            <p className="service-sub-title">Debts & loans</p>
                            <p className="service-sub-title">Housing & property</p>
                            <p className="service-sub-title">Expenses & entertainment</p>
                        </div>
                    </div>
                </div>

                
                <div class="card">
                    <div className="single-service-card d-flex p-4">
                        <div>
                            <img src={serviceEight} alt="services" className="service-card-img" />
                        </div>
                        <div className="ml-4">
                            <h5 className="text-uppercase">Wage Subsidy Program</h5>
                            <p className="service-sub-title">We prepare the claims for Wage Subsidy Programs for Apprentices</p>
                            <p className="service-sub-title">We prepare Claims for Wages Subsidy for other employees </p>
                            <p className="service-sub-title">We Speak to Employment Agencies on behalf of you to prepare Wage Subsidy claims</p>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div className="single-service-card d-flex p-4">
                        <div>
                            <img src={serviceFourteen} alt="services" className="service-card-img" />
                        </div>
                        <div className="ml-4">
                            <h5 className="text-uppercase">SUPERANNUATION</h5>
                            <p className="service-sub-title">Superannuation Guarantee Charge</p>
                            <p className="service-sub-title">Self-managed super funds management</p>
                            <p className="service-sub-title">Salary sacrifice</p>
                            <p className="service-sub-title">Additional contributions</p>
                        </div>
                    </div>
                </div>

                <div className="card">
                    <div className="single-service-card d-flex p-4">
                        <div>
                            <img src={serviceFifteen} alt="services" className="service-card-img" />
                        </div>
                        <div className="ml-4">
                            <h5 className="text-uppercase">Equipment Finance</h5>
                            <p className="service-sub-title">We help with processing application for Business Equipment Loans</p>
                            <p className="service-sub-title">We provide help with business documents towards your equipment Loans</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AccountServicesCard;