import React from 'react';
import './OurClients.css';
import facebook from '../../../images/facebook.png';
import instagram from '../../../images/instagram.png';
import twitter from '../../../images/twitter.png';
import client1 from '../../../images/client.png';
import client2 from '../../../images/client2.png';
import client3 from '../../../images/client3.png';

const OurClients = () => {
    return (
        <div className="my-5 pt-2">
            <div className="container">
                <h4 className="text-center text-uppercase">QBS Beloved Regulars</h4>
                <p className="text-center gray-text">Take a look at what some of our regulars have to say about our services.</p>
                <div className="row mt-5 client-card-container">
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="client-card text-right custom-shadow position-relative mb-3">
                            <div className="text-left">
                                <img className="img-fluid" src={client1} alt="" />
                            </div>
                            <div className="client-card-details position-absolute">
                                <h5 className="text-uppercase mb-0">marina liza</h5>
                                <h5 className="brand-color">Creative</h5>
                                <p className="client-review">As an artist I rarely had time to manage my taxes efficiently, QBS stepped up and saved me hassle and time, now I leave everything to them!</p>

                                {/* Client social icon */}
                                <ul className="ml-auto">
                                    <li>
                                        <a href="#"> <img src={facebook} alt="Facebook" className="img-fluid client-social-icon" /></a>
                                    </li>
                                    <li>
                                        <a href="#"> <img src={instagram} alt="Instagram" className="img-fluid client-social-icon my-lg-4 my-md-4 my-sm-3 my-2" /></a>
                                    </li>
                                    <li>
                                        <a href="#">  <img src={twitter} alt="Twitter" className="img-fluid client-social-icon" /></a>
                                    </li>
                                </ul>
                            </div>
                            <p className="client-review-bottom text-justify px-3 pb-3">As an artist I rarely had time to manage my taxes efficiently, QBS stepped up and saved me hassle and time, now I leave everything to them!</p>
                        </div>
             
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="client-card text-right custom-shadow position-relative mb-3">
                            <div className="text-left">
                                <img className="img-fluid" src={client2} alt="" />
                            </div>
                            <div className="client-card-details position-absolute">
                                <h5 className="text-uppercase mb-0">dr. sadia jh</h5>
                                <h5 className="brand-color">Medical</h5>
                                <p className="client-review">Managing accounts has become so easy now thanks to them, definitely would recommend for all accounting needs.</p>

                                {/* Client social icon */}
                                <ul className="ml-auto">
                                    <li>
                                        <a href="#"> <img src={facebook} alt="Facebook" className="img-fluid client-social-icon" /></a>
                                    </li>
                                    <li>
                                        <a href="#"> <img src={instagram} alt="Instagram" className="img-fluid client-social-icon my-lg-4 my-md-4 my-sm-3 my-2" /></a>
                                    </li>
                                    <li>
                                        <a href="#">  <img src={twitter} alt="Twitter" className="img-fluid client-social-icon" /></a>
                                    </li>
                                </ul>
                            </div>
                            <p className="client-review-bottom text-justify px-3 pb-3">Managing accounts has become so easy now thanks to them, definitely would recommend for all accounting needs.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="client-card text-right custom-shadow position-relative">
                            <div className="text-left">
                                <img className="img-fluid" src={client3} alt="" />
                            </div>
                            <div className="client-card-details position-absolute">
                                <h5 className="text-uppercase mb-0">sadi hk</h5>
                                <h5 className="brand-color">Fitness</h5>
                                <p className="client-review">QBS communicates with their clients and listens to their accounting needs like no other, I can rest assured knowing they got my back!</p>

                                {/* Client social icon */}
                                <ul className="ml-auto">
                                    <li>
                                        <a href="#"> <img src={facebook} alt="Facebook" className="img-fluid client-social-icon" /></a>
                                    </li>
                                    <li>
                                        <a href="#"> <img src={instagram} alt="Instagram" className="img-fluid client-social-icon my-lg-4 my-md-4 my-sm-3 my-2" /></a>
                                    </li>
                                    <li>
                                        <a href="#">  <img src={twitter} alt="Twitter" className="img-fluid client-social-icon" /></a>
                                    </li>
                                </ul>
                            </div>
                            <p className="client-review-bottom text-justify px-3 pb-3">QBS communicates with their clients and listens to their accounting needs like no other, I can rest assured knowing they got my back!</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OurClients;