import React from 'react';
import './WhoWeAre.css'

const WhoWeAre = () => {
    return (
        <div className="who-we-are-container">
            <div className="container">
                <div className="col-lg-7 col-md-10 col-sm-12 text-white">
                    <div className="py-5">
                        <h4>Excellence Guaranteed</h4>
                        <p>The team at QBS consists of highly qualified like minded individuals who aim to give you the perfect solution for your situation. 
                        </p>
                        <p className="mb-1">Client satisfaction is the top most priority here at QBS, and our team members strive in interacting with our clients personally every step of the way.</p>
                        {/* <button className="read-more-btn">read more</button> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WhoWeAre;