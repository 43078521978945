import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import logo from '../../images/logo.png'
import './NavBar.css'

const NavBar = () => {
    return (
        <div className="gradient-bg">
        <div className='container'>
            <Navbar className="py-3" expand="md">
                <Link className="ml-n3" to="/"><img className="brand-logo" src={logo} alt="logo" /></Link>
                <Navbar.Toggle className="hamburger-menu" aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="nav-items ml-auto">
                        <Link className="menu-link" to="/">Home</Link>
                        <Link className="menu-link" to="/services">Services</Link>
                        <Link className="menu-link" to="/about-us">About Us</Link>
                        <Link className="menu-link" to="/contact-us">Contact Us</Link>
                    </Nav>                       
                </Navbar.Collapse>
            </Navbar>
        </div>
    </div>
    );
};

export default NavBar;