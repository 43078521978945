import React, { useEffect } from 'react';
import './ContactUs.css';
import cardIcon1 from '../../images/svg/location.svg';
import cardIcon2 from '../../images/svg/phone_1.svg';
import cardIcon3 from '../../images/svg/mail.svg';

const ContactUs = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div>
            <div className="contact-us-header d-flex align-items-center text-white">
                <div className="container">
                    <h1 className="display-3 text-center text-uppercase text-shadow about-us-title">contact us</h1>
                </div>
            </div>

            {/* ------- contact us body ------- */}
            <div className="container">
                {/* ------------  about us body ---------- */}
                <div className="my-5 text-center">
                    <h4 className="text-uppercase">get in touch with us</h4>
                    <p className="gray-text">Contact us to get started with all your accounting needs and more!</p>

                    <div className="mt-5">
                        <div className="row justify-content-center">
                            <div className="col-md-4 col-sm-6">
                                <div className="contact-card">
                                    <img className="contact-card-icon" src={cardIcon1} alt="contact icon" />
                                    <p className="font-weight-bold contact-card-title">Lugarno, NSW 2210, Australia</p>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <div className="contact-card">
                                    <img className="contact-card-icon" src={cardIcon2} alt="contact icon" />
                                    <p className="font-weight-bold contact-card-title">+ 61 0433 247 095</p>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <div className="contact-card mt-lg-0 mt-md-0 mt-sm-3 mt-3">
                                    <img className="contact-card-icon" src={cardIcon3} alt="contact icon" />
                                    <p className="font-weight-bold contact-card-title">info@quickbookservices.com.au</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* -------------- contact us form ----------- */}
                <div className="my-5 pt-5 text-center">
                    <h4 className="text-uppercase">leave a comment</h4>
                    <p className="gray-text">You can also send us an enquiry and we will respond to you with our solutions as quickly as possible.</p>

                    {/* form */}
                    <div className="text-left form-body mx-auto d-lg-block d-md-block d-sm-none d-none">
                        <form className="mt-4">
                            <div className="d-flex mb-4">
                                <div className="col-lg-2 col-md-3 col-sm-3">
                                    <p className="contact-form-label">Your Name</p>
                                </div>
                                <div className="col-lg-10 col-md-9 col-sm-9">
                                    <input className="contact-form-input" type="text" />
                                </div>
                            </div>

                            <div className="d-flex mb-4">
                                <div className="col-lg-2 col-md-3 col-sm-3">
                                    <p className="contact-form-label extra-margin">Email</p>
                                </div>
                                <div className="col-lg-10 col-md-9 col-sm-9">
                                    <input className="contact-form-input" type="text" />
                                </div>
                            </div>

                            <div className="d-flex">
                                <div className="col-lg-2 col-md-3 col-sm-3">
                                    <p className="contact-form-label">Enquiry</p>
                                </div>
                                <div className="col-lg-10 col-md-9 col-sm-9">
                                    <textarea className="contact-form-input" rows="5" type="text" />
                                </div>
                            </div>

                            <div className="submit-enquiry-btn text-center mt-4">
                                <input className=" gradient-bg" type="submit" value="Submit Enquiry" />
                            </div>
                        </form>
                    </div>

                    {/* for small device */}
                    <div className="d-lg-none d-md-none d-sm-block d-block mt-4">
                        <form action="">
                            <div className="mb-3">
                                <input className="contact-form-input pl-3" type="text" placeholder="Your Name" />
                            </div>
                            <div className="mb-3">
                                <input className="contact-form-input pl-3" type="email" placeholder="Email" />
                            </div>
                            <div className="mb-3">
                                <textarea rows="5" className="contact-form-input pl-3" type="email" placeholder="Enquiry" />
                            </div>

                            <div className="text-center ">
                                <input className=" gradient-bg small-submit-btn" type="submit" value="Submit Enquiry" />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactUs;