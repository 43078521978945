import React from 'react';
import './Footer.css';
import { FiMapPin } from "react-icons/fi";
import { BsEnvelope } from "react-icons/bs";
import { FaPhoneAlt } from "react-icons/fa";
import { Link } from 'react-router-dom';
import footerLogo from '../../images/logo.png';
import facebook from '../../images/footer-fb.png';
import instagram from '../../images/footer-insta.png';
import twitter from '../../images/footer-twr.png';

const Footer = () => {
    return (
        <div className="footer-bg py-5 gradient-bg text-white">
            <div className="container">
                <div className="row">
                    <div className="col-md-4 col-sm-6">
                        <div className="newsletter">
                            <h5 className="text-uppercase mb-3">newsletter</h5>
                            <input className="py-1 pl-2 rounded-left newsletter-input" type="search" placeholder="enter your email" />
                            <input type="submit" className="text-white footer-go-btn brand-bg py-1 px-2 rounded-right" value="Go" />
                            <div className="mt-4">
                                <p className="mb-2 off-white"><i className="brand-color mr-2"><FiMapPin /></i>Lugarno, NSW 2210, Australia</p>
                                <p className="mb-2 off-white"><i className="brand-color mr-2"><FaPhoneAlt /></i>+ 61 0433 247 095</p>
                                <p className="off-white"><i className="brand-color mr-2"><BsEnvelope /></i>info@quickbookservices.com.au</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <div className="information  mt-lg-0 mt-md-0 mt-sm-0 mt-4">
                            <h5 className="text-uppercase mb-3">information</h5>
                            <Link className="off-white link-style" to="#">Testimonials</Link><br />
                            <Link className="off-white link-style" to="#">Privacy Policy</Link><br />
                            <Link className="off-white link-style" to="#">Terms and conditions</Link>
                        </div>
                    </div>
                    <div className="col-md-2 col-sm-6">
                        <div className="help mt-lg-0 mt-md-0 mt-sm-4 mt-4">
                            <h5 className="text-uppercase mb-3">help</h5>
                            <Link className="off-white link-style" to="/contact-us">Contact us</Link><br />
                            <Link className="off-white link-style" to="#">FAQs</Link><br />
                            <Link className="off-white link-style" to="#">Site Map</Link>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <div className="p mt-lg-0 mt-md-0 mt-sm-4 mt-4">
                           <Link to="/"> <img src={footerLogo} alt="Footer logo" className="img-fluid"/></Link>
                            <p className="my-3 off-white">Follow us on our social media below to know more about us and our services!</p>

                            <ul className="d-flex pt-1">
                                <li>
                                    <a href="#"><img className="footer-social-icon" src={facebook} alt="Facebook"/></a>
                                </li>
                                <li>
                                <a className="mx-3" href="#"><img className="footer-social-icon" src={instagram} alt="Facebook"/></a>
                                </li>
                                <li>
                                <a href="#"><img className="footer-social-icon" src={twitter} alt="Facebook"/></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <hr className="horizontal-line mt-5 mb-0" />
                <small className="off-white">Copyright © {`${new Date().getFullYear()}`} All rights reserved by QBS</small>
            </div>
           
        </div>
    );
};

export default Footer;