import React, { useEffect } from 'react';
import DifferentServices from './DifferentServices/DifferentServices';
import HomePageHeader from './HomePageHeader/HomePageHeader';
import OurClients from './OurClients/OurClients';
import WhoWeAre from './WhoWeAre/WhoWeAre';

const Home = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div>
            <HomePageHeader />
            <DifferentServices />
            <WhoWeAre />
            <OurClients />
        </div>
    );
};

export default Home;