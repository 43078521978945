import React, { useEffect } from 'react';
import './AboutUs.css';
import { FaCircle } from "react-icons/fa";
import aboutUsImg1 from '../../images/about-us/Asset 1.png';
import aboutUsImg2 from '../../images/about-us/Asset 2.png';

const AboutUs = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div>
            <div className="about-us-header text-white d-flex align-items-center">
           <div className="container">
                <div className="">
                    <h1 className="text-uppercase text-center display-3 text-shadow about-us-title">about us</h1>
                </div>
           </div>
        </div>
            <div className="container">
                {/* -------------- first section of body --------------- */}
                <div className="mt-lg-5 mt-md-5 mt-2">
                    <div className="row">
                        <div className="col-md-7">
                            <div className="pt-lg-3 pt-md-0 pt-3">
                                <h5 className="brand-color">Welcome to QBS</h5>
                                <h3 className="text-capitalize header-title my-3">Find the right accounting <br />For Yourself or Your Business</h3>
                                <p className="gray-text">
                                    Our creative services are fit for any situation. No matter if you are an individual or a business, we provide efficient, seamless solutions that save you time and resources. With QBS you make sure that your accounting related woes are a thing of the past!</p>

                                <div className="d-lg-flex d-md-flex d-sm-flex d-block mt-4">
                                    <div>
                                        <h6 className="bullet-text"><i className="mr-2 brand-color circle-icon"><FaCircle /></i>Learn from customer feedback</h6>
                                        {/* <h6 className="bullet-text"><i className="mr-2 brand-color circle-icon"><FaCircle /></i>Flexible Creative Solutions</h6> */}
                                    </div>
                                    <div className="ml-lg-5 ml-md-0 ml-sm-2 ml-0">
                                        <h6 className="bullet-text"><i className="mr-2 brand-color circle-icon"><FaCircle /></i>High saving potential</h6>
                                        {/* <h6 className="bullet-text"><i className="mr-2 brand-color circle-icon"><FaCircle /></i>24/7 Customer support</h6> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="pt-lg-0 pt-md-5 pt-sm-0 pt-0">
                                <img src={aboutUsImg1} alt="" className="img-fluid p-lg-0 p-md-0 p-5" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* -------------- Second section of body --------------- */}
                <div className="">
                    <div className="row align-items-center">
                        <div className="col-md-5">
                            <div className="p-lg-4 p-md-0 p-0 d-lg-block d-md-block d-sm-none d-none">
                                <img src={aboutUsImg2} alt="" className="img-fluid" />
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="pt-lg-3 pt-md-3 pt-0 pl-lg-5 pl-md-5">
                                <h3 className="">Our Clients</h3>
                                <p className="gray-text">
                                    Our clients include a range of small business across a variety of industries including but not limited to medical, creative, Media Production, Fitness Industries, consumer goods, manufacturing, IT, Automotive Industries, Disability Services, Non for Profit organization, Investors in CryptoCurrency, Residential and business properties,  Sole Traders, Handyman business, Construction industries, Uber and Taxi Operators and Individuals, etc.</p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* -------------- last section of body --------------- */}
                <div className="mb-5 mt-3">
                    <h4 className="text-uppercase py-3">who we are</h4>
                    <p className="gray-text">
                        QBS Accounting & Bookkeeping Services is comprised of Certified Practicing Accountants that provide Accounting Practices in the full range of accounting, taxation, SMSF, and business advisory services to clients all over Australia.
                    </p>
                    <p className="gray-text">
                        <i className="brand-color circle-icon-small"><FaCircle /></i>
                        We ensure you are taking advantage of all the tax deductions you are entitled to, some common and others not so commonly known.
                    </p>
                    <p className="gray-text">
                        <i className="brand-color circle-icon-small"><FaCircle /></i>
                        Our team oversees all client accounts, is responsive and quick, fully compliant, and ensures we meet all your business and regulatory deadlines.
                    </p>
                    <p className="gray-text">
                        <i className="brand-color circle-icon-small"><FaCircle /></i>
                        We streamline your bookkeeping practice
                    </p>
                    <p className="gray-text">
                        <i className="brand-color circle-icon-small"><FaCircle /></i>
                        We identify your medium and long term needs
                    </p>
                    <p className="gray-text">
                        <i className="brand-color circle-icon-small"><FaCircle /></i>
                        We provide constructive advice on your business needs to improve and implement suggested guidelines at a pace comfortable to you.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default AboutUs;