import React from 'react';
import './DifferentServices.css';
import { useState } from 'react';
import AccountServicesCard from './AccountServicesCard';
import BookKeepingCard from './BookKeepingCard';

const DifferentServices = () => {
    const [showMoreServices, setShowMoreServices] = useState(false);
    const handleSeeMOreBtn = () => {
        setShowMoreServices(true)
    }
    return (
        <div className="text-center my-5 pt-lg-4 pt-md-4 pt-0">
            <div className="container">
                <h4 className="solid-color text-uppercase service-title">We offer all accounting and bookkeeping services and more</h4>
                <div className="line mx-auto">
                  
                </div>

                <div className="services-card text-left pt-2">
                    <AccountServicesCard />
                    {
                        showMoreServices &&

                        <BookKeepingCard />

                    }
                    <div className="text-center mt-2">
                        <button onClick={handleSeeMOreBtn} className="see-more-btn">see all services</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DifferentServices;