import React from 'react';
import AccountServicesCard from '../Home/DifferentServices/AccountServicesCard';
import BookKeepingCard from '../Home/DifferentServices/BookKeepingCard';
import './Services.css';

const Services = () => {
    return (
        <>
            <div className="service-page-header d-flex align-items-center justify-content-center">
                <div className="container">
                    <h1 className="display-3 text-center text-uppercase text-shadow text-white">Services</h1>
                </div>


            </div>

            <div className="all-services">
                <div className="text-center my-5 pt-lg-4 pt-md-4 pt-0">
                    <div className="container">
                        <h4 className="solid-color text-uppercase service-title">We offer all accounting and bookkeeping services and more</h4>
                        <div className="line mx-auto">

                        </div>

                        <div className="services-card text-left pt-2">
                            <AccountServicesCard />
                            <BookKeepingCard />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Services;