import React from 'react';
import serviceOne from '../../../images/svg/1.svg';
import serviceTwo from '../../../images/svg/2.svg';
import serviceThree from '../../../images/svg/3.svg';
import serviceFour from '../../../images/svg/4.svg';
import serviceFive from '../../../images/svg/5.svg';
import serviceSix from '../../../images/svg/6.svg';
import TaxCard from './TaxCard';

const BookKeepingCard = () => {
    return (
        <>
        <div className="mt-3 mb-3">
            <h5 className="text-uppercase brand-color text-center mb-1">BookKeeping</h5>
            <p className="gray-text text-center pt-1 mb-0">Our bookkeeping team manages and prepares your books on time with speed and accuracy.</p>
            <p className="gray-text text-center mb-0">We take the stress out of you by taking time consuming tasks from you to help you to concentrate on what you know best</p>
            <p className="gray-text text-center">We come to you.</p>
            <div class="card-columns">
                <div class="card">
                    <div className="single-service-card d-flex p-4">

                        <img src={serviceOne} alt="services" className="service-card-img" />

                        <div className="ml-4">
                            <h5 className="text-uppercase">bookkeeping</h5>
                            <p className="service-sub-title">MYOB</p>
                            <p className="service-sub-title">Xero</p>
                            <p className="service-sub-title">QuickBooks</p>
                            <p className="service-sub-title">Cloud based services</p>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div className="single-service-card d-flex p-4">
                        <img src={serviceTwo} alt="services" className="service-card-img" />
                        <div className="ml-4">
                            <h5 className="text-uppercase">invoicing</h5>
                            <p className="service-sub-title">Sales processing</p>
                            <p className="service-sub-title">Payments received</p>
                            <p className="service-sub-title">Issuing invoices</p>
                            <p className="service-sub-title">Reconciling payments</p>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div className="single-service-card d-flex p-4">
                        <div>
                            <img src={serviceThree} alt="services" className="service-card-img" />
                        </div>
                        <div className="ml-4">
                            <h5 className="text-uppercase">payg & payroll</h5>
                            <p className="service-sub-title">PAYG & group tax processing</p>
                            <p className="service-sub-title">Payroll processing</p>
                            <p className="service-sub-title">Single touch payroll(STP) lodgement</p>
                            <p className="service-sub-title">Payroll reports</p>
                            <p className="service-sub-title">Payroll tax</p>
                        </div>
                    </div>
                </div>


                <div class="card p-3">
                    <div className="single-service-card d-flex p-4">
                        <div>
                            <img src={serviceFour} alt="services" className="service-card-img" />
                        </div>
                        <div className="ml-4">
                            <h5 className="text-uppercase">bank/cards</h5>
                            <p className="service-sub-title">Bank reconciliations</p>
                            <p className="service-sub-title">Credit card reconciliations</p>
                            <p className="service-sub-title">Bulk electronic bank transfers</p>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div className="single-service-card d-flex p-4">
                        <div>
                            <img src={serviceFive} alt="services" className="service-card-img" />
                        </div>
                        <div className="ml-4">
                            <h5 className="text-uppercase">Data</h5>
                            <p className="service-sub-title">Data entry</p>
                            <p className="service-sub-title">Data filing</p>
                            <p className="service-sub-title">Spreadsheet updating</p>
                            <p className="service-sub-title">Data validation</p>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div className="single-service-card d-flex p-4">
                        <div>
                            <img src={serviceSix} alt="services" className="service-card-img" />
                        </div>
                        <div className="ml-4">
                            <h5 className="text-uppercase">leave management</h5>
                            <p className="service-sub-title">Personal & carers leave</p>
                            <p className="service-sub-title">Annual leave</p>
                            <p className="service-sub-title">Long service leave</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <TaxCard />
        </>
    );
};

export default BookKeepingCard;