import React from 'react';
import './HomePageHeader.css';
import headerBanner from '../../../images/header-banner.png';
import videoIcon from '../../../images/video-icon.png';

const HomePageHeader = () => {
    return (
        <div className="mt-3">
            <div className="container">
                <div className="row d-flex align-items-center">
                    <div className="col-md-7 text-column">
                        <div className="left-side-header-content">
                            <h2 className="solid-color text-capitalize header-title">focus on growing your business<br/> trust the <span className="brand-color">Accounting</span> to us</h2>
                            <p className="gray-text pr-5">QBS Accounting & Bookkeeping Services is comprised of Certified Practicing Accountants that provide Accounting Practices in the full range of Accounting, Taxation, SMSF, Accounting for Non Profit Organizations, and Business Advisory Services to clients all over Australia.</p>
                            {/* <button className="get-quote-btn">Get a quote</button>
                            <button className="watch-video-btn">
                                <img src={videoIcon} alt="video icon" className="img-fluid watch-video-icon" />
                               <span className="watch-video-text">watch video</span>
                            </button> */}
                        </div>
                    </div>
                    <div className="col-md-5 p-0 img-column">
                        <img src={headerBanner} alt="header banner" className="img-fluid header-img" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomePageHeader;